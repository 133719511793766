@import '../../styles/mixins';

.shortcutUrlGenerator {
  @include flexbox(column, start, center);
  height: 100%;
  padding: 24px;
  padding-top: 120px;
  gap: 12px;

  > input {
    width: 100%;
    max-width: 480px;
    padding: 12px 18px;
    border: 1px solid var(--border-color);
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
  }

  > div {
    width: 100%;
    max-width: 480px;

    &.switchContainer {
      @include flexbox(row, start, center);
      gap: 8px;
    }
  }

  > p {
    &.description {
      margin-top: -6px;
      padding: 0 6px;
      width: 100%;
      max-width: 480px;
      font-size: 0.8rem;
      color: var(--text-secondary-color);
    }
  }

  > div.panelContainer {
    position: absolute;
    @include flexbox(column, center, center);
    bottom: var(--space-4x);
    gap: var(--space);

    > button {
      color: var(--text-color);
      background-color: var(--background-secondary-color);
      padding: 8px 16px;
      border: 1px solid var(--border-color);

      &:not([disabled]):hover {
        color: var(--text-always-white);
        background-color: var(--link-hover-color);
      }

      border-radius: 12px;
      -webkit-border-radius: 12px;
      -moz-border-radius: 12px;
      -ms-border-radius: 12px;
      -o-border-radius: 12px;
    }
  }

  > p {
    color: var(--text-color);
  }
}
