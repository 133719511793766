@import '../../styles/_mixins.scss';

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
  // background-color: var(--switch-background-color);
  border: 1px solid var(--switch-border);
  @include border-radius(16px);

  &.checked {
    background-color: var(--switch-background-color-checked);

    > span {
      background-color: var(--switch-slider-color-checked);
      @include transform-container(translateX(24px));
    }
  }

  > input[type='checkbox'] {
    opacity: 0;
    width: 0;
    height: 0;
  }

  > span {
    position: absolute;
    cursor: pointer;
    top: 4px;
    left: 4px;
    width: 16px;
    height: 16px;
    background-color: var(--switch-slider-color);
    @include border-radius(50%);
  }
}
